import 'bootstrap/dist/css/bootstrap.css';
import Parser from 'html-react-parser';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import { Container } from './styles';

import { enUS, esEs, ptBr } from './texts';

export default function BodyDenuncia() {
  const { idioma } = useContext(LanguageContext);

  const description = [ptBr, enUS, esEs];

  return (
    <Container>
      <div>{Parser(description[idioma])}</div>
    </Container>
  );
}
