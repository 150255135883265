import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import HeaderDenuncias from "../components/Denuncia"
import BodyDenuncia from "../components/Denuncia/Body"
import Footer from "../components/Footer"
import Chat from "../components/Chat"

export default function Denuncias() {
  return (
    <Layout>
      <SEO title="Denúncias" />
      <HeaderDenuncias />
      <BodyDenuncia />
      <Footer />
      <Chat />
    </Layout>
  )
}
