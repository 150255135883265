export const ptBr = `  <p>
Em 2021, a Hub tornou-se parte do Grupo Magazine Luiza e, desde então,
a empresa se integrou à cultura, aos valores e ao propósito do Magalu.
</p>

<p>
Como parte importante do Ecossistema Magalu, a Hub está inserida no
nosso Programa de Integridade.
</p>

<p>
O Magalu sempre teve uma cultura de honestidade muito forte e sempre
esteve comprometido com a legalidade, com a ética e com as melhores
práticas de mercado. Por isso, em 2017, estruturou todas as suas ações
voltadas a garantir a conformidade legal, regulatória e a probidade em
um Programa de Integridade, com o objetivo de assegurar que os
princípios e os valores do Grupo sejam perpetuados, e formalizar e
evidenciar para todos, que no nosso ambiente corporativo, deve
prevalecer a ética, a boa conduta e a transparência.
</p>

<p>
Essa forte cultura de honestidade, que começou a ser construída desde
a fundação do Magalu, permeia as ações dos Magalus e já está bem
enraizada na nossa rotina, sendo fomentada dia a dia.
</p>

<p>
O Programa de Integridade conta com uma série de diretrizes e regras
que visam apoiar e orientar todos aqueles que compõem o Ecossistema
Magalu: seus colaboradores, fornecedores e parceiros. Conta, também,
com diferentes instrumentos e/ou mecanismos de monitoramento e
controle que tem a finalidade de garantir a eficácia e efetividade das
principais ações que suportam os nossos objetivos de integridade
</p>

<p>
Entre as diretrizes, vale destacar o${' '}
<a
  href="https://ri.magazineluiza.com.br/Download.aspx?Arquivo=TuAIVGsvCZMsQfo9LjMDGg=="
  target="_blank"
>
  Código de Ética e Conduta de Colaboradores
</a>
, documento base do Programa de Integridade, que reforça a missão,
visão, valores e cultura do Ecossistema Magalu. Nele, estão contidos
os nossos Inegociáveis - condutas não admitidas em hipótese alguma,
sob nenhuma circunstância -, as diretrizes de comportamento que
espera-se dos colaboradores e do relacionamento com terceiros (como
clientes e fornecedores), as responsabilidades da liderança e demais
temas importantes.
</p>

<p>
O Magalu preza muito por suas relações e tem como princípio
relacionar-se apenas com parceiros que pactuam com seus valores e com
sua cultura de integridade. Assim, estabelece um${' '}
<a
  href="https://ri.magazineluiza.com.br/Download.aspx?Arquivo=vxPtUIOS3ZskXZ9dODr4bg=="
  target="_blank"
>
  Código de Conduta de Fornecedores
</a>
, que reflete as expectativas mínimas do Magalu para realizar negócios
e firmar parcerias.
</p>
<p>
O Programa de Integridade é responsabilidade de toda a organização,
não de uma área específica, e deve ser seguido e respeitado por todos
os colaboradores da organização, independente de seu nível
hierárquico.
</p>
<p>
Para facilitar a comunicação de possíveis violações ao Programa de
Integridade, disponibilizamos um${' '}
<a
  href="https://canalconfidencial.com.br/magazineluiza/"
  target="_blank"
>
  Canal de Denúncias
</a>
, que pode ser utilizado de forma anônima por qualquer colaborador ou
terceiro que, de boa-fé, deseje reportar situação de não conformidade
da qual tenha conhecimento. Todas as denúncias recebidas são avaliadas
e investigadas, com a garantia de anonimato, proteção aos denunciantes
contra eventuais retaliações e apuradas por um canal isento.
</p>

<p>
Para saber mais sobre nosso Programa de Integridade,${' '}
<a
  href="https://ri.magazineluiza.com.br/show.aspx?idCanal=uejB36qwmqaREGD6e486ZQ=="
  target="_blank"
>
  clique aqui.
</a>
</p>`;

export const enUS = `  <p>
In 2021, the HubFintech company became part of the Magazine Luiza
Group and, since then, the company has been integrated into the
culture, values and purpose of Magalu.
</p>

<p>
As an important part of the Magalu Ecosystem, the HubFintech is part
of our Integrity Program.
</p>

<p>
Magalu always had a very strong culture of honesty and has always been
committed to legality, ethics and the best market practices.
Therefore, in 2017, Magalu structured all its actions aimed at
ensuring legal, regulatory and probity compliance in an Integrity
Program, with the objective of ensuring that the Group's principles
and values are perpetuated, formalizing and demonstrating to all that
ethics, good conduct and transparency must prevail in our corporate
environment.
</p>

<p>
This strong culture of honesty, which began to be built since the
foundation of Magalu, permeates the actions of the Magalus and is
already well rooted in our routine, being fostered day by day.
</p>

<p>
The Integrity Program has a series of guidelines and rules that aim to
support and guide all those who compose the Magalu Ecosystem: its
employees, suppliers and partners. It also has different monitoring
and control instruments and/or mechanisms that aim at ensuring the
affinity and effectiveness of the main actions that support our
integrity objectives.
</p>

<p>
Among the guidelines, it is worth mentioning the${' '}
<a
  href="https://ri.magazineluiza.com.br/Download.aspx?Arquivo=TuAIVGsvCZMsQfo9LjMDGg=="
  target="_blank"
>
  Código de Ética e Conduta de Colaboradores
</a>
, the base document of the Integrity Program, which reinforces the
mission, vision, values and culture of the Magalu Ecosystem. It
contains our Non-negotiables - conducts not permitted under any
circumstances, the behavioral guidelines expected from employees and
the relationship with third parties (such as customers and suppliers),
the responsibilities of leadership and other important topics.
</p>

<p>
Magalu values its relationships a lot and has as principle the
establishment of relationships with partners who agree with its values
and culture of integrity. Thus, Magalu establishes a${' '}
<a
  href="https://ri.magazineluiza.com.br/Download.aspx?Arquivo=vxPtUIOS3ZskXZ9dODr4bg=="
  target="_blank"
>
  Código de Conduta de Fornecedores
</a>
, which reflects Magalu's minimum expectations for doing business and
establishing partnerships.
</p>

<p>
The Integrity Program is the responsibility of the entire
organization, not from a specific area, and must be followed and
respected by all employees of the organization, regardless of their
hierarchical level.
</p>

<p>
To facilitate the communication of possible violations of the
Integrity Program, we provide a${' '}
<a
  href="https://canalconfidencial.com.br/magazineluiza/"
  target="_blank"
>
  Canal de Denúncias
</a>
, which can be used anonymously by any employee or third party who, in
good faith, wishes to report a non-compliance situation of which they
are aware. All complaints received are evaluated and investigated,
with the guarantee of anonymity, protection of whistleblowers against
possible retaliation and verified through an exempt channel.
</p>

<p>
To learn more about our Integrity Program,${' '}
<a
  href="https://ri.magazineluiza.com.br/show.aspx?idCanal=uejB36qwmqaREGD6e486ZQ=="
  target="_blank"
>
  click here.
</a>
</p>`;

export const esEs = ` <p>
En 2021, Hub pasó a formar parte del Grupo Magazine Luiza y, desde
entonces, la empresa se ha sumado a la cultura, valores y propósito de
Magalu.
</p>
<p>
Como parte importante del Ecosistema de Magalu, el Hub es parte de
nuestro Programa de Integridad.
</p>
<p>
Magalu siempre ha tenido una cultura de honestidad muy fuerte y
siempre ha estado comprometida con legalidad, ética y mejores
prácticas de mercado. Por eso, en 2017 estructuró todos los sus
acciones encaminadas a asegurar el cumplimiento legal, normativo y la
probidad en un Programa de Integridad, con el objetivo de que los
principios y valores del Grupo se perpetúen, y formalizar y demostrar
a todos que en nuestro entorno empresarial, debe prevalecer la ética,
la buena conducta y la transparencia.
</p>
<p>
Esta fuerte cultura de honestidad, que comenzó a construirse desde la
fundación de Magalu, impregna las acciones del Magalus y ya está bien
arraigado en nuestra rutina, promoviéndose día a día.
</p>
<p>
El Programa de Integridad cuenta con una serie de lineamientos y
reglas que tienen como objetivo apoyar y orientar todos los que
integran el Ecosistema Magalu: sus empleados, proveedores y socios.
Asimismo, cuenta con diferentes instrumentos y/o mecanismos de
seguimiento y control que tienen como finalidad de garantizar la
eficacia y efectividad de las principales acciones que sustentan
nuestros objetivos de integridad.
</p>
<p>
Entre los lineamientos, se destaca el${' '}
<a
  href="https://ri.magazineluiza.com.br/Download.aspx?Arquivo=TuAIVGsvCZMsQfo9LjMDGg=="
  target="_blank"
>
  Código de Ética e Conduta de Colaboradores
</a>
, documento base del Programa de Integridad, que refuerza la misión,
visión, valores y cultura del Ecosistema Magalu. En él, nuestros No
Negociables están contenidos - conducta no admitida bajo ninguna
circunstancia, bajo ninguna circunstancia-, las pautas de
comportamiento esperadas de los empleados y la relación con terceros
(como clientes y proveedores), responsabilidades de liderazgo y otros
temas importantes.
</p>

<p>
Magalu valora mucho sus relaciones y su principio es relacionarse solo
con socios que estar de acuerdo con sus valores y con su cultura de
integridad. Así, establece un${' '}
<a
  href="https://ri.magazineluiza.com.br/Download.aspx?Arquivo=vxPtUIOS3ZskXZ9dODr4bg=="
  target="_blank"
>
  Código de Conduta de Fornecedores
</a>
, que refleja las expectativas mínimas de Magalu para hacer negocios y
firmar asociaciones.
</p>

<p>
El Programa de Integridad es responsabilidad de toda la organización,
no de un área específica, y debe ser seguido y respetado por todos los
empleados de la organización, independientemente de su nivel
jerárquico.
</p>

<p>
Para facilitar la denuncia de posibles violaciones del Programa de
Integridad, proporcionamos un${' '}
<a
  href=" https://canalconfidencial.com.br/magazineluiza/"
  target="_blank"
>
  Canal de Denúncias
</a>
, que puede ser utilizado de forma anónima por cualquier empleado o
tercero que, de buena fe, desea comunicar una situación de
incumplimiento de la que tenga conocimiento. Todas las denuncias
recibidas son evaluadas e investigadas, con garantía de anonimato,
protección de denunciantes ante posibles represalias e investigados
por un canal exento.
</p>

<p>
Para obtener más información sobre nuestro Programa de Integridad,
haga${' '}
<a
  href="https://ri.magazineluiza.com.br/show.aspx?idCanal=uejB36qwmqaREGD6e486ZQ=="
  target="_blank"
>
  clic aquí.
</a>
</p>`;
