import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import LogoHubfintechBranco from '../../images/svg/Logo-hubfintech-branco.svg';
import Menu from '../Menu';
import { Container, Header } from './styles';

export default function HeaderDenuncias() {
  const { idioma } = useContext(LanguageContext);

  var title = ['Compliance e Canal de Denúncias', 'Compliance and Reporting Channel', 'Cumplimiento y Canal de Notificación'];

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={LogoHubfintechBranco} alt="Hubfintech somos hub" />
        </Link>
        <h2>
          {title[idioma]}
          <hr />
        </h2>
      </Header>
      <Menu scrollToForm={() => console.log('to form')} />
    </Container>
  );
}
